import { render, staticRenderFns } from "./footer.vue?vue&type=template&id=73a1e017&"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.10.2@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports